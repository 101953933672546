.theme-text-left {
  text-align: left;
}
html[dir="ltr"] .theme-text-left {
  text-align: right;
}
/*
-------------------------------------

-------------------------------------
*/

.justify_content_flex_end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}
