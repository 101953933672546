/* layout */
.mobile_page {
  min-height: 100vh;
}
.mobile_image_background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  z-index: -1;
}
/* 
--------------------------------------
صفحه انتخاب زبان
--------------------------------------
*/

.mobile_select_lang {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 40px;
}
.mobile_select_lang .mobile_logo {
  margin: 0 0 30px 0;
}
.mobile_select_lang .select_lang_btn {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 15px;
  width: 100%;
  margin: 0 0 15px 0;
  border-radius: 15px;
  cursor: pointer;
}
