.news_item {
  background: #fff;
  border-radius: 25px;
  padding: 40px 90px;
}

.news_item .news-img {
  border-radius: 25px;
  overflow: hidden;
}

.news_item .news-info {
  color: #717171;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 25px 0;
}

.news_item .news-info .blog_intro {
  padding-bottom: 12px;
  position: relative;
}
.news_item .news-info .blog_intro span {
  display: inline-block;
}
.news_item .news-info .blog_intro:before {
  content: "";
  background: #ff5e15;
  width: 100%;
  position: absolute;
  height: 1px;
  bottom: 0;
  right: 0;
}

.news_item .news-info h1 {
  color: #717171;
  font-size: 22pt;
  font-weight: bold;
  letter-spacing: -1px;
  margin: 0;
}

.news_item .news-info span:not(:last-child) {
  margin-left: 30px;
}

.news_item .news-text p {
  font-size: 11pt;
  color: #717171;
  text-align: justify;
  line-height: 2;
  text-align-last: center;
}

.news_item .news-text {
  margin-top: 32px;
}

.comment_section {
  background: #fff;
  border-radius: 25px;
  padding: 20px 15px;
}

.comment_section h6 {
  color: #717171;
  font-size: 12pt;
  font-weight: bold;
}

.comment_section .comment_send .form-input,
.form-textarea {
  position: relative;
}

.comment_section .comment_send .form-input input,
.form-textarea textarea {
  border: 0 none !important;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 10pt;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-left: 40px;
  resize: none;
}

.comment_section .comment_send .mdi {
  position: absolute;
  left: 5px;
  bottom: -4px;
  font-size: 17pt;
  color: #cccccc;
}

.comment_section .comment_send .send {
  border-radius: 25px;
  width: 100%;
  background: #ff5e15;
  color: #fff;
}

.comment_section .comment_list .item {
  background: #fff;
  border-radius: 25px;
  position: relative;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
}

.comment_section .comment_list .item:not(:last-child) {
  margin: 0 0 20px 0;
}

.comment_section .comment_list .item:before {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  position: absolute;
  height: 1px;
  top: 40px;
  right: 0;
}

.comment_section .comment_list .item .avater {
  background: #f9faff;
  border-radius: 55%;
  padding: 10px 18px;
  margin-left: 25px;
  display: flex;
  color: #868ba3;
  width: 60px;
  z-index: 2;
  height: 60px;
  font-size: 20pt;
}

.comment_section .comment_list .item .top_bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.comment_section .comment_list .item .top_bar .mdi,
.date {
  color: #868ba3;
}

.comment_section .comment_list .item .top_bar .dis {
  font-size: 10pt;
  font-weight: bold;
}

.comment_section .comment_list .item .top_bar .dis .name {
  color: #00539f;
  margin-left: 35px;
}

.comment_section .comment_list .item .top_bar .icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment_section .comment_list .item .top_bar .icon span {
  display: flex;
  background: #f9faff;
  padding: 0 4px;
  color: #868ba3;
  font-size: 10pt;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  margin: 0 4px;
}

.comment_section .comment_list .item .top_bar .icon span .mdi {
  margin: 0 0 -2px 0;
  font-size: 12pt;
}

.comment_section .comment_list .item .text {
  font-size: 11pt;
  color: #717171;
}
.news_item table thead th {
  text-align: center;
  color: #00539f;
}
.news_item table tbody td {
  text-align: center;
}
/* ===== Responsive ===== */

/* Medium only */

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .news_item {
    border-radius: 15px;
    padding: 30px 40px;
  }
}

/* Small only */

@media screen and (max-width: 39.9375em) {
  .news_item {
    border-radius: 10px;
    padding: 20px;
  }
  .news_item .news-info h1 {
    font-size: 15pt;
  }
  .news_item .news-info .blog_intro {
    width: 100%;
    display: flex;
    text-align: right;
    flex-direction: column;
    font-size: 10pt;
  }
  .comment_section .comment_list .item .avater,
  .comment_section .comment_list .item:before {
    display: none;
  }
  .comment_section .comment_list .item {
    border-radius: 10px;
    padding: 10px;
  }
  .comment_section .comment_list .item .top_bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
  }
  .comment_section .comment_list .item .top_bar .dis .name {
    margin-left: 10px;
  }
  .comment_section .comment_list .item .text {
    font-size: 9pt;
  }
  .comment_section .comment_list .item .top_bar .dis {
    font-size: 9pt;
  }
  .comment_section .comment_list .item .top_bar .icon span {
    margin: 0 2px;
  }
}
