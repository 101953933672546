@import url(./theme.css);
@import url(../fonts/abel.css);
@import url(../fonts/IRANSansWeb.css);
@import url(../fonts/Proxima.css);
@import url(./materialdesignicons.min.css);
@import url(./lightgallery.css);

html {
  overflow-x: hidden;
  /* direction: rtl; */
  overflow-y: scroll;
}

body {
  min-height: 100vh;
  font-family: irsans;
  background-color: #f2f5f7;
}


* {
  outline: none !important;
}

.getting_data {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#root {
  min-height: 100vh;
}

a,
button {
  outline: none !important;
  text-decoration: none !important;
}

.no_mouse {
  pointer-events: none;
  user-select: none;
}

img {
  max-width: 100%;
}

ul li,
ol li {
  list-style-type: none;
}

ul,
ol {
  margin: 0;
}

/* ==== */

.fln {
  float: none !important;
}

/* ----- margin ----- */

.mb {
  margin-bottom: 15px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-15 {
  margin-bottom: 15px;
}

.m0 {
  margin: 0;
}

.mb0 {
  margin-bottom: 0;
}

.ml-15 {
  margin-left: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.pos-r {
  position: relative;
}

.margin_minus {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.paragraph {
  color: #585858;
  text-align: justify;
}

.button.primary {
  color: #fff;
  border-radius: 0;
  background: #ff5e15;
}

/* page content */

.page-content {
  min-height: 60vh;
  padding: 60px 0;
  background: #f2f5f7;
}
/* small only */
@media screen and (max-width: 39.9375em) {
  .page-content {
    padding: 35px 0;
  }
}

/*
======================
bread crumbs
======================
*/

ul {
  margin: 0;
}

ul li {
  list-style-type: none;
}

a {
  text-decoration: none !important;
}

.bread_crumb {
  width: 100%;
  display: flex;
  align-items: center;
  background: url(../images/breadcrumbs.png) no-repeat;
  background-size: cover;
  justify-content: center;
  padding: 20px 0;
}

.bread_crumb li a,
.bread_crumb li span {
  color: #fff;
  position: relative;
  margin: 5px;
  font-size: 15px;
  margin: auto;
  padding: 0 15px;
}

.bread_crumb li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bread_crumb li a:before {
  content: "\f141";
  position: absolute;
  height: 30px;
  left: -8px;
  top: -3px;
  bottom: 0;
  margin: auto;
  font-size: 15pt;
  font-family: "Material Design Icons";
  color: #ff5e15;
}

.bread_crumb span.mdi.mdi-home-outline {
  font-size: 20px;
}

/*page title*/

.page_title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 0 10px 0;
}

.page_title svg {
  fill: #ff5e15;
  width: 45px;
  height: 45px;
  padding: 0;
  margin: 0 0 0 12px;
}

.page_title span {
  color: #fff;
  font-size: 20px;
  font-weight: 100;
}

.br-menu {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

/*white  panel*/

.white-panel {
  background: #fff;
  margin: 0 0 20px 0;
  padding: 15px;
  border-radius: 15px;
}

/* responsive */
/* small only */
@media screen and (max-width: 39.9375em) {
  .page_title svg {
    width: 35px;
    height: 35px;
  }
  .page_title span {
    font-size: 17px;
  }
}
/*
--------------------------------------
pagination
--------------------------------------
*/

.pagination li button {
  padding: 8px 16px;
  display: block;
  line-height: 1.5;
  cursor: pointer;
  margin: 0 3px;
}

.pagination li.is-active button {
  color: #fff;
  background: #00539f;
  cursor: default;
}

.news_item {
  overflow: hidden;
  overflow-x: scroll;
}


@media all and (display-mode: standalone) {
  body {
    
  }
}

/*
====================================================================
====================================================================
====================================================================
====================================================================
LTR Style
====================================================================
====================================================================
====================================================================

*/

html[dir="ltr"], 
html[dir="ltr"] body,
html[dir="ltr"] .paragraph,
html[dir="ltr"] .bread_crumb li a,
html[dir="ltr"] .bread_crumb li span,
html[dir="ltr"] .news-item .title{
  font-family: 'Proxima Nova Soft',irsans;
}


html[dir="ltr"] body {
}

html[dir="ltr"] .main-header .small-menu svg {
  margin: 0 0 0 7px;
}

html[dir="ltr"] .main-header .small-menu li:not(:last-child) a:before {
  left: auto;
  right: 0;
}

html[dir="ltr"] .sidebar {
  left: auto;
  right: 0%;
  transform: translateX(150%);
}

html[dir="ltr"] .side-menu li a {
  text-align: left;
}

html[dir="ltr"] .sidebar.is-active {
  transform: translateX(0);
}

html[dir="ltr"] .side-social ul {
  justify-content: flex-start;
}

html[dir="ltr"] .news-item {
  direction: ltr;
}

html[dir="ltr"] .news-item .posted label {
  margin: 0 5px 0 0;
}

html[dir="ltr"] .main_title:before {
  right: auto;
  left: 0;
}

html[dir="ltr"] .news-item .icons li:not(:last-child) {
  margin: 0 10px 0 0;
}

html[dir="ltr"] .news-item .title:before {
  right: auto;
  left: 0;
}

html[dir="ltr"] .board-item {
  direction: ltr;
}

html[dir="ltr"] .board-item .image {
  margin: 0 20px 0 0;
}

html[dir="ltr"] .column,
html[dir="ltr"] .columns,
html[dir="ltr"] .column:last-child:not(:first-child),
html[dir="ltr"] .columns:last-child:not(:first-child) {
  float: left;
}
html[dir="ltr"] .column.row.row,
html[dir="ltr"] .row.row.columns {
  float: none;
}
html[dir="ltr"] .c-information li .mdi {
  margin: 0 10px 0 20px;
}

html[dir="ltr"] .galaxy-input input,
html[dir="ltr"] .galaxy-textarea textarea {
  padding-left: 0.5rem;
  padding-right: 35px;
}

html[dir="ltr"] .galaxy-input label,
html[dir="ltr"] .galaxy-textarea label {
  right: auto;
  left: 0;
}
html[dir="ltr"] .galaxy-input .mdi,
html[dir="ltr"] .galaxy-textarea .mdi {
  left: auto;
  right: 5px;
}
html[dir="ltr"] .galaxy-input:not(.has-val) input:focus ~ label,
html[dir="ltr"] .galaxy-textarea:not(.has-val) textarea:focus ~ label {
  transform: translate(-20%, -100%) scale(0.6);
}
html[dir="ltr"] .page_title svg {
  margin: 0 12px 0 0;
}
html[dir="ltr"] .bread_crumb li a:before {
  right: -8px;
  left: auto;
}
html[dir="ltr"] .tabs-title {
  padding: 14px 7px 12px 7px;
  font-size: 11pt;
}
html[dir="ltr"] .product_item .tabs {
  padding: 0 5px;
}
html[dir="ltr"] .product_item .tabel-download th:first-child,
html[dir="ltr"] td:first-child {
  text-align: left;
}
html[dir="ltr"] .news_item .news-info span:not(:last-child) {
  margin-right: 30px;
  margin-left: 0;
}
html[dir="ltr"] .comment_section .comment_send .form-input input,
html[dir="ltr"] .form-textarea textarea {
  padding-left: 0.5rem;
  padding-right: 40px;
}
html[dir="ltr"] .comment_section .comment_send .mdi {
  left: auto;
  right: 5px;
}
html[dir="ltr"] .comment_section .comment_list .item .avater {
  margin-left: 0;
  margin-right: 25px;
}
html[dir="ltr"] .comment_section .comment_list .item .top_bar .dis .name {
  margin-right: 35px;
  margin-left: 0;
  float: left;
}
html[dir="ltr"] .news-item.inner .image {
  margin: 0 30px 0 0;
}

/* left arrow ro right */
html[dir="ltr"] .board-item .readmore .mdi:before,
html[dir="ltr"] .product_item_List .dis i:before {
  content: "\F13E";
}
html[dir="ltr"] .bread_crumb li a:before {
  content: "\f142";
}

/* bold texts */
html[dir="ltr"] .news-item .title,
html[dir="ltr"] .news-item .posted label,
html[dir="ltr"] .news-item .readmore,
html[dir="ltr"] .news-item .date,
html[dir="ltr"] .galaxy-input:not(.has-val) input:focus ~ label,
html[dir="ltr"] .product_item .context h1,
html[dir="ltr"] .tabs-title,
html[dir="ltr"] .news_item .news-info h1 {
  font-weight: 700;
}
/* normal font-weight */
html[dir="ltr"] .product_item_List .dis,
html[dir="ltr"] .bread_crumb li a,
html[dir="ltr"] .bread_crumb li span,
html[dir="ltr"] .news_item .news-info,
html[dir="ltr"] .comment_section,
html[dir="ltr"] .comment_section .comment_list .item .text,
html[dir="ltr"] .product_item .context p {
  font-weight: 400;
}

/* sidebar menu */
html[dir="ltr"] .side-menu li span {
  text-align: left;
}
html[dir="ltr"] .side-menu li a i.mdi-chevron-left:before {
  transform: rotate(180deg);
}

/* iransans to open sans */
html[dir="ltr"] body,
html[dir="ltr"] .galaxy-input input,
html[dir="ltr"] .galaxy-input label,
html[dir="ltr"] .c-information li,
html[dir="ltr"] .tabs-title,
html[dir="ltr"] .main_title,
html[dir="ltr"] .news_item .news-info,
html[dir="ltr"] .news_item .news-info h1,
html[dir="ltr"] .news_item .news-text p,
html[dir="ltr"] .comment_section,
html[dir="ltr"] .comment_section h6,
html[dir="ltr"] .comment_section .comment_list .item .text,
html[dir="ltr"] .news-item .date,
html[dir="ltr"] .board-item .title,
html[dir="ltr"] .product_item_List .dis,
html[dir="ltr"] .product_item .context p,
html[dir="ltr"] .product_item .context h1,
html[dir="ltr"] .product_item .tabel-zebra th,
html[dir="ltr"] .product_item .tabel-zebra td,
html[dir="ltr"] .product_item .tabel-download td,
html[dir="ltr"] .paragraph {
}
html[dir="ltr"] .galaxy-input:not(.has-val) input:focus ~ label,
html[dir="ltr"] .galaxy-textarea:not(.has-val) textarea:focus ~ label,
html[dir="ltr"] .large-menu .large-menu-item .name,
html[dir="ltr"] .news-item .posted label,
html[dir="ltr"] .news-item .title,
html[dir="ltr"] .news-item .readmore,
html[dir="ltr"] .board-item .readmor,
html[dir="ltr"] .large-menu .large-menu-item .name {
  font-weight: 700;
}

/* Amiri Arabic font */
html[lang="ar"] body,
html[lang="ar"] .galaxy-input input,
html[lang="ar"] .galaxy-input label,
html[lang="ar"] .c-information li,
html[lang="ar"] .tabs-title,
html[lang="ar"] .main_title,
html[lang="ar"] .news_item .news-info,
html[lang="ar"] .news_item .news-info h1,
html[lang="ar"] .news_item .news-text p,
html[lang="ar"] .comment_section,
html[lang="ar"] .comment_section h6,
html[lang="ar"] .comment_section .comment_list .item .text,
html[lang="ar"] .news-item .date,
html[lang="ar"] .board-item .title,
html[lang="ar"] .product_item_List .dis,
html[lang="ar"] .product_item .context p,
html[lang="ar"] .product_item .context h1,
html[lang="ar"] .product_item .tabel-zebra th,
html[lang="ar"] .product_item .tabel-zebra td,
html[lang="ar"] .product_item .tabel-download td,
html[lang="ar"] .paragraph {
  font-family: "Amiri", sans-serif !important;
}
html[lang="ar"] .galaxy-input:not(.has-val) input:focus ~ label,
html[lang="ar"] .galaxy-textarea:not(.has-val) textarea:focus ~ label,
html[lang="ar"] .large-menu .large-menu-item .name,
html[lang="ar"] .news-item .posted label,
html[lang="ar"] .news-item .title,
html[lang="ar"] .news-item .readmore,
html[lang="ar"] .board-item .readmor,
html[lang="ar"] .large-menu .large-menu-item .name {
  font-family: "Amiri", sans-serif !important;
  font-weight: 700;
}

.dlappDiv{
  animation-duration: 0.2s;
  animation-fill-mode: both;
  right: 0;
  bottom: 0;
  height: 60px;
  width: 60px;
  position: fixed;
  margin-right: 18px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 18px;
  border-radius: 50%;
  cursor: pointer;
  background-size: 55% 63%;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 999999999;
  transition: box-shadow .2s ease-in-out !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .06), 0 2px 32px rgba(0, 0, 0, .16) !important;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
  background-color: #0f55be;
  opacity: 0.4;
}
.dlappDiv:hover {
  box-shadow: 0 2px 8px 
rgba(0, 0, 0, .09), 0 4px 40px
  rgba(0, 0, 0, .24) !important;
  transition: all 0.2s !important;
  -webkit-transform: scale(1.05) !important;
  -moz-transform: scale(1.05) !important;
  -ms-transform: scale(1.05) !important;
  -o-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
  -webkit-box-shadow: 0 4px 42px 0 rgba(0, 0, 0, .25) !important;
  -moz-box-shadow: 0 4px 42px 0 rgba(0, 0, 0, .25) !important;
  box-shadow: 0 4px 42px 0 rgba(0, 0, 0, .25) !important;
  opacity: 1;
}


.dlappDiv img {
  max-width: 90%;
}

.notifbar {
  position: fixed;
  width: 95%;
  color: white;
  background-color: #2b67cb;
  padding: 15px;
  opacity: 0.9;
  text-shadow: 1px 1px 1px black;
  z-index: 99999;
  border-radius: 20px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}


.dlappDiv.iosIco{
  text-align: center;
  padding-top: 2px;
}

.comment_section .c-information li .c-text a,
.comment_section .c-information li .c-text,
.comment_section .c-link li a{
  color: #063549;
}